<template>
  <div class="changeUkey">
    <div class="changeMatter" v-if="!changeing">
      <div class="item-title">变更注意事项</div>
      <p>（1）确认是否下载并安装【豸信CA数字证书助手驱动】。如“未安装”，请“<a class="blue" @click="$util.downloadById(CONFIG.APPID)">下载安装</a>”。（安装完成后请刷新页面）</p>
      <p>（2）将需要变更的Ukey插入电脑USB接口，Ukey密码验证成功后进行变更。（若遇到控件拦截，需要全部运行控件）</p>
      <p>（3）推荐使用【IE】、【Chrome】、【Edge】、【360】浏览器。 </p>
      <p><span class="el-icon-warning"></span> 变更请插入一个Ukey操作，以免造成数据冲突，造成变更失败。</p>
    </div>
    <template v-if="!changeing">
      <div class="item-title">变更信息</div>
      <div class="item-box">
        <div class="info-box old">
          <div class="tt">原证书信息</div>
          <div class="info">
            <div><span>所属机构/O：</span><span>{{info.oldApplyFormEntity.orgInfoEntity.organization}}</span></div>
            <div><span>所属部门/OU：</span><span>{{info.oldApplyFormEntity.orgInfoEntity.organizationalUnit}}</span></div>
            <template v-if="info.oldApplyFormEntity.certType!==2">
              <div><span>姓名/CN：</span><span>{{info.oldApplyFormEntity.personInfoEntity.name}}</span></div>
              <div><span>证件类型：</span><span>{{IdentType(info.oldApplyFormEntity.personInfoEntity.identType)}}</span></div>
              <div><span>证件号码：</span><span>{{info.oldApplyFormEntity.personInfoEntity.identNo}}</span></div>
            </template>
            <div><span>法人姓名：</span><span>{{info.oldApplyFormEntity.orgInfoEntity.legalPersonName}}</span></div>
            <div><span>法人身份证号：</span><span>{{info.oldApplyFormEntity.orgInfoEntity.legalPersonIdentNo}}</span></div>
            <div class="sealImg" v-if="info.applyFormEntity.sealInfoEntitys"><img :src="info.oldApplyFormEntity.sealInfoEntitys[0].patternFilePath"></div>
          </div>
        </div>
        <div class="arrows">
          变更为
          <img src="@/assets/icons/service/arrows.png" alt="">
        </div>
        <div class="info-box">
          <div class="tt">变更后的证书信息</div>
          <div class="info">
            <div><span>所属机构/O：</span><span>{{info.applyFormEntity.orgInfoEntity.organization}}</span></div>
            <div><span>所属部门/OU：</span><span>{{info.applyFormEntity.orgInfoEntity.organizationalUnit}}</span></div>
            <template v-if="info.applyFormEntity.certType!==2">
              <div><span>姓名/CN：</span><span>{{info.applyFormEntity.personInfoEntity.name}}</span></div>
              <div><span>证件类型：</span><span>{{IdentType(info.applyFormEntity.personInfoEntity.identType)}}</span></div>
              <div><span>证件号码：</span><span>{{info.applyFormEntity.personInfoEntity.identNo}}</span></div>
            </template>
            <div><span>法人姓名：</span><span>{{info.applyFormEntity.orgInfoEntity.legalPersonName}}</span></div>
            <div><span>法人身份证号：</span><span>{{info.applyFormEntity.orgInfoEntity.legalPersonIdentNo}}</span></div>
            <div class="sealImg" v-if="info.applyFormEntity.sealInfoEntitys"><img :src="info.applyFormEntity.sealInfoEntitys[0].patternFilePath"></div>
          </div>
        </div>
      </div>
      <el-button @click="connectUkey" type="primary">确定变更</el-button>
    </template>
    <div class="changeing" v-else>
      <div class="icon">
        <img src="@/assets/icons/service/loading-icon.png" alt="">
      </div>
      <div class="tt">证书变更中</div>
      <div class="hint">提示：请不要关闭浏览器，不要拔出Ukey，在此界面等待更新完毕，以免造成证书更新失败</div>
    </div>
  </div>
</template>

<script>
import { IdentType } from '@/utils/typeMatch'
export default {
  props: ['info'],
  data () {
    return {
      IdentType,
      changeing: false,
      transId: '',
      isFail: '',
      pkcs10: ''
    }
  },
  created () {
    this.transId = this.$route.query.transId
  },
  methods: {
    connectUkey () {
      this.$parent.Ukey.connect()
        .then(certSn => {
          if (certSn.toLowerCase() !== this.info.applyFormEntity.certSn.toLowerCase()) {
            this.$alert(
              '当前插入Ukey非办理变更业务所使用的，请更换Ukey',
              '提示',
              {
                confirmButtonText: '我知道了'
              }
            )
          } else {
            this.$parent.certInfo = { certSn }
            this.$parent.ukeyLogined = () => {
              this.changeing = true
              this.getCertDn()
            }
            this.$parent.ukeyLoginShow = true
          }
        })
        .catch(err => {
          if (err.message) this.$message.error(err.message)
        })
    },
    getPostponeCert () {
      this.$api
        .getPostponeCert({
          transId: this.transId,
          pkcs10: this.pkcs10
        })
        .then(res => {
          this.certData = res.data
          this.$parent.Ukey.writeCert(this.certData)
            .then(res => {
              if (res) {
                this.$parent.getTransactionDetail()
              } else {
                this.$parent.transactionDetail.transactionEntity.status = 14
              }
            })
            .catch(err => {
              this.$parent.transactionDetail.transactionEntity.status = 14
              this.$message.error(err.message)
            })
        })
        .catch(() => {
          this.changeing = false
        })
    },
    getCertDn () {
      this.$api
        .getCertDn({
          transId: this.transId
        })
        .then(res => {
          this.$parent.Ukey.getPkcs10(res.msg, this.info.keyLength)
            .then(res => {
              this.pkcs10 = res.data.CSR
              this.getPostponeCert()
            })
            .catch(err => {
              this.$parent.transactionDetail.transactionEntity.status = 14
              this.$message.error(err.message)
            })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}
.changeUkey {
  padding: 16px 24px 32px;
  .item-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .arrows {
      color: #303b50;
      img {
        width: 43px;
        height: 14px;
        margin-left: 8px;
      }
    }
    .info-box {
      &.old {
        .info {
          background: #f7faff;
        }
      }
      .tt {
        font-size: 18px;
        font-weight: bold;
        color: #303b50;
        line-height: 19px;
        margin-bottom: 16px;
      }
    }
    .info {
      width: 548px;
      background: #f7fffc;
      padding: 24px;
      & > div {
        display: flex;
        line-height: 19px;
        font-size: 14px;
        font-weight: bold;
        color: #303b50;
        margin-bottom: 32px;
        &:last-child {
          margin: 0;
        }
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & > span:first-child {
          font-weight: normal;
          flex-shrink: 0;
          color: #6c737b;
        }
      }
      .sealImg {
        img {
          width: 98px;
          height: auto;
          display: inline-block;
        }
      }
    }
  }
  .el-button {
    width: 182px;
    display: block;
    margin: 0 auto;
    margin-top: 32px;
  }
  .changeing {
    height: 718px;
    padding-top: 140px;
    text-align: center;
    .icon {
      width: 52px;
      height: 52px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px #e4e5e6;
      margin: 0 auto;
      border-radius: 50%;
      padding: 5px;
      img {
        width: 42px;
        height: 42px;
        animation: loading 0.6s linear infinite;
      }
    }
    .tt {
      font-size: 16px;
      color: #303b50;
      line-height: 21px;
      margin: 24px auto;
    }
    .hint {
      width: 828px;
      background: #f7faff;
      border-radius: 2px;
      line-height: 94px;
      font-size: 16px;
      color: #3a6afe;
      margin: 0 auto;
    }
  }
}
.changeMatter {
  p {
    font-size: 14px;
    color: #303b50;
    margin-bottom: 32px;
  }
  p:last-child {
    color: #ffb500;
  }
}
</style>