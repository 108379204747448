<template>
  <div class="publicGuidelines">
    <div class="publicTitle">变更指南</div>
    <div class="publicListMain">
      <div class="publicTitle">办理流程</div>
      <ul>
        <li>插入Ukey</li>
        <li class="publicOverlength">修改证书信息并确认</li>
        <li>上传资料</li>
        <li>资料审核</li>
        <li>付款</li>
        <li>在线变更</li>
        <li>业务完成 </li>
      </ul>
    </div>
    <div class="publicListMain">
      <div class="publicTitle">注意事项</div>
      <ol>
        <li>（1）确认是否下载并安装【豸信CA数字证书助手驱动】。如“未安装”，请“<a class="blue" @click="$util.downloadById(CONFIG.APPID)">下载安装</a>”。（安装完成后请刷新页面）</li>
        <li>（2）将需要变更的Ukey插入电脑USB接口，Ukey密码验证成功后进行变更（若遇到控件拦截，需要全部运行控件）</li>
        <li>（3）修改证书信息并提交，审核通过后即可在线变更证书，推荐使用【IE】、【Chrome】、【Edge】、【360】浏览器。</li>
        <li class="publicSpecial"><span class="el-icon-warning"></span>变更请插入一个Ukey操作，以免造成数据冲突，造成变更失败。</li>
      </ol>
    </div>
    <div class="publicListMain">
      <div class="publicTitle">所需证明资料 </div>
      <div>
        <div class="publicMeans">1、【机构证书】</div>
        <p>（1）信息修改成功后，下载《数字证书变更申请表》并填写完整，加盖公章</p>
        <p>（2）营业执照（机构证件）原件复印件，加盖公章</p>
        <p>（3）法人身份证原件复印件，加盖公章</p>
        <p>（4）本次业务办理人身份证原件复印件，加盖公章</p>
      </div>
      <div>
        <div class="publicMeans publicOther">2、【机构个人证书】</div>
        <p>（1）信息修改成功后，下载《数字证书变更申请表》并填写完整，加盖公章</p>
        <p>（2）营业执照（机构证件）原件复印件，加盖公章</p>
        <p>（3）法人身份证原件复印件，加盖公章</p>
        <p>（4）证书持有人身份证原件复印件，加盖公章</p>
        <p>（5）本次业务办理人身份证原件复印件，加盖公章</p>
      </div>

    </div>
    <div class="publicBtn">
      <el-button @click="certChange" type="primary">变更申请</el-button>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    certChange () {
      this.$parent.Ukey.connect()
        .then(certSn => {
          this.$api.getCertBySn({ certSn, transType: 2 }).then(res => {
            if (res.code === '0') {
              this.$parent.certInfo = { ...res.data, certSn }
              this.$parent.agreementShow = true
            } else if (res.code === '306') {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '继续办理',
                type: 'warning'
              }).then(() => {
                this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
              })
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                callback: () => { }
              })
            }
          })
        })
        .catch(err => {
          if (err.message) this.$message.error(err.message)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.publicGuidelines {
  background: #fff;
}
</style>